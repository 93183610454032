.root {
  background-color: #fafafa;
}

.wrapper {
  max-width: 1136px;
  margin: 0 auto;
  min-height: 400px;
  padding: 20px 0;
}

.vehicles {
  display: flex;
  flex-wrap: wrap;
}

.vehicle {
  padding: 0 10px;
  width: 33.333%;
  margin-bottom: 20px;

  @media(max-width: 991px) {
    width: 50%;
  }

  @media(max-width: 768px) {
    width: 100%;
  }

  .content {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 10px 12px;
    min-height: 250px;
    @media(max-width: 768px) {
      min-height: auto;
    }

    h1 {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      color: #000000;

      span {
        font-size: 12px;
        line-height: 1.5;
        color: #707070;
        font-weight: 400;
      }
    }
  }

  .innerContent {
    display: flex;
    justify-content: space-between;

    .price {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;
      color: #0e3e92;
      margin-bottom: 10px;
      text-align: right;
      display: block;

      img {
        width: 8px;
      }
    }
    :global {
      .ant-carousel .slick-dots {
        bottom: -19px;
      }
    }
  }
}

.vehicleFeatures {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 12px 0 10px;

  li {
    width: auto;
    margin-bottom: 8px;
    padding: 0 7px;

    &:last-of-type {
      padding-right: 7px;
    }

    &:first-of-type {
      padding-left: 7px;
    }

    span {
      font-size: 12px;
      margin-left: 4px;
    }
  }
}

.photos {
  max-width: 150px;
  margin-top: 30px;

  :global {
    .ant-carousel .slick-dots {
      bottom: -26px;
    }
  }
}

.segment {
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 12px;
  line-height: 1.5;
  color: #707070;
  padding: 4px;
  max-width: 100px;
  margin-left: auto;
  text-align: center;
}
.rentButton {
  margin-left: auto;
  text-align: right;
  display: block;
  margin-top: 4px;
}
.modal {
  :global {
    .ant-modal-body {
      padding: 0;
      min-height: 400px;
    }
  }
  object {
    width: 100%;
    min-height: 396px;
  }
}
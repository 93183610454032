.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-spin-dot-item {
  background-color: #0e3e92;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: initial;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.DateRangePicker_picker {
  font-family: 'Poppins', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.DateRangePickerInput_arrow {
  display: none;
}

.DateRangePickerInput .DateInput_1:first-of-type {
  display: none;
}

.SingleDatePickerInput,
.DateRangePickerInput,
.SingleDatePickerInput .DateInput,
.DateRangePickerInput .DateInput {
  background: none;
  border: none
}

.SingleDatePickerInput .DateInput_input,
.DateRangePickerInput .DateInput_input {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 4px;
  outline: 0;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  color: #000000;

  height: 50px;
  border-radius: 5px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding-left: 40px;
  border-width: 0;
  font-weight: 400;
}

::-webkit-input-placeholder {
  color: #272727;
  opacity: 0.5;
  font-weight: 400;
  font-size: 14px;
}

:-ms-input-placeholder {
  color: #272727;
  opacity: 0.5;
  font-weight: 400;
  font-size: 14px;
}

::placeholder {
  color: #272727;
  opacity: 0.5;
  font-weight: 400;
  font-size: 14px;
}

.CalendarDay__selected_span {
  background: #ffefea;
  /* //background */
  color: #212b35;
  /* //text */
  border: 1px solid #e4e7e7;
  /* $light-red; //default styles include a border */
}

/* // Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #ed4817;
  /* $dark-red; */
  color: white;
  border: 1px double #e4e7e7
}

/* // Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #ed4817;
  color: white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border: 1px double #e4e7e7
}

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #ffefea;
  border: 1px solid #e4e7e7;
  color: #212b35;
}
.root {
  border-radius: 5px;
  background-color: #e6e6e6;
  padding: 10px 30px 20px;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @media(max-width: 991px) {
    padding: 10px 15px 20px;
  }

  .wrapper {
    display: flex;
    margin: 0 -8px;

    @media(max-width: 991px) {
      display: block;
    }
  }

  h2 {
    margin-bottom: 14px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.53;
    color: #0e3e92;
  }

  :global {
    .formItem {
      width: 265px;
      padding: 0 8px;

      &:nth-child(1),
      &:nth-child(2) {
        width: 170px;
      }

      @media(max-width: 991px) {
        width: 100% !important;
        margin-bottom: 30px;
      }
    }
  }

  .errorText {
    display: none;
  }

  .error {
    input {
      border: 1px solid #e30717;
    }
    .errorText {
      font-size: 12px;
      color: #e30717;
      margin-top: 6px;
      display: block;
    }
  }
}

.root.readOnly {
  background: #fff;

  h2 {
    font-size: 13px;
    font-weight: 500;
    color: #0e3e92;
  }

  :global {
    .formItem {
      label {
        color: #656565;
        font-weight: 500;
      }

      input {
        background-color: #f7f7f7;
        box-shadow: none;
        border: 0;
      }
    }
  }
}
.root {
  border-radius: 5px;
  background-color: #e6e6e6;
  padding: 10px 30px 20px;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @media(max-width: 991px) {
    padding: 10px 15px 20px;
  }

  h2 {
    margin-bottom: 14px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.53;
    color: #0e3e92;
  }

  .wrapper {
    display: flex;

    @media(max-width: 991px) {
      display: block;
    }

    ul {
      width: 50%;
      @media(max-width: 991px) {
        width: 100%;
      }

      li {
        margin-bottom: 15px;

        @media(max-width: 991px) {
          display: flex;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        strong {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.5;
          color: #0e3e92;
          margin-left: 35px;

          @media(max-width: 991px) {
            margin-left: 20px;
          }

          span {
            font-weight: 400;
            font-size: 12px;
          }
        }

        :global {
          label {
            width: 230px;
            @media(max-width: 991px) {
              width: 183px;
            }

            > span {
              &:nth-child(2) {
                width: 200px;
              }
            }
          }

          .tooltip-icon {
            margin-left: auto;
          }

          .ant-checkbox {
            margin-right: 12px
          }

          span {
            font-size: 14px;
            color: #646464;
          }
        }
      }
      .navigation {
        @media(max-width: 991px) {
          margin-bottom: 36px !important;
        }
        strong {
          margin-left: 54px;
          @media(max-width: 991px) {
            margin-left: 40px;
          }
        }
      }
    }
  }
}

.count {
  display: flex;
  font-size: 16px;

  >span {
    margin-left: 16px;
    line-height: 22px;
    width: 150px;
    @media(max-width: 991px) {
      width: 120px
    }

    img {
      margin-left: 4px;
    }
  }

  .countButtons {
    button {
      background: none;
      border: 1px solid #0e3e92;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      font-size: 16px;
      text-align: center;
      line-height: 22px;
      color: #0e3e92;
    }

    span {
      width: 25px;
      display: inline-block;
      text-align: center;
    }
  }
}


.vehicleRent {
  margin-left: 49px;

  img {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }

  span {
    font-size: 13px !important;
    color: #0e3e92 !important;
  }
}

.rentTermsTooltip {
  max-width: 200px;

  h3 {
    font-size: 15px;
    font-weight: bold;
    color: #0e3e92;
    margin-bottom: 8px;
  }

  ul {
    li {
      font-size: 12px;
      line-height: 2.08;
      color: #000000;

      strong {
        margin-left: 10px;
      }
    }
  }

  p {
    font-size: 10px;
    line-height: 1.5;
    color: #707070;
    margin-top: 6px;
  }
}

.extrasDisplay {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 8px 24px;
  border-radius: 5px;
  margin-bottom: 10px;

  h2 {
    font-size: 13px;
    font-weight: 500;
    color: #0e3e92;
  }

  .wrapper {
    margin: 0 -8px;
  }

  ul {
    display: flex;
    justify-content: flex-start;

    @media(max-width: 991px) {
      overflow-x: auto;
      white-space: nowrap;
      display: block;
    }

    li {
      font-size: 14px;
      color: #656565;
      padding: 0 8px;
      display: flex;

      @media(max-width: 991px) {
        width: auto;
        display: inline-block;
        vertical-align: middle;
      }
      &:last-of-type{ 
        margin-left: 0;
      }

      span {
        margin-left: 6px;
      }
      strong {
        margin-left: 10px;
        font-size: 16px;
      }
      >img {
        margin-left: 6px;
      }
    }
  }
}
.root {

  :global {

    .ant-checkbox {
      border-color: #bababa;
    }

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-color: #bababa !important;

      &:after {
        width: 6.714286px;
        height: 11.142857px;
      }
    }

    .ant-checkbox-checked {
      &:after {
        border-color: #bababa;
      }

      .ant-checkbox-inner {
        border-color: #bababa;
        background-color: #fff;

        &:after {
          border-color: #ed4817;
        }
      }
    }
  }
}

.rounded {
  :global {
    .ant-checkbox-inner {
      width: 22px;
      height: 22px;
      border-radius: 50%;
    }
    .ant-checkbox-inner:after {
      left: 28%;
    }
    .ant-checkbox-checked:after {
      border-radius: 50%;
    }
  }
}
.root {
  background-color: #fafafa;
  min-height: 800px;

  @media (max-width: 991px) {
    min-height: 1250px;
  }
}

.wrapper {
  max-width: 1128px;
  margin: 0 auto;
  position: relative;
  padding: 0 8px;

  >h1 {
    padding: 20px 0 15px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #000000;
    margin: 0;
  }

  .banner {
    height: 162px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #0e3e92c7;
      border-radius: 4px;
    }
  }

  .bannerInner {
    position: absolute;
    top: 85px;
    left: 48px;

    @media(max-width: 991px) {
      right: 0;
      left: 0;
      margin: 0 auto;
      padding: 0 16px;
      // position: static;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      color: #ffffff;

      @media(max-width: 991px) {
        text-align: center;
        margin-top: 25px;
      }

      img {
        max-width: 70px;
      }
    }
  }
}

.errorText {
  display: none;
}

.error {

  input,
  textarea {
    border: 1px solid #e30717;
  }

  .errorText {
    font-size: 12px;
    color: #e30717;
    margin-top: 6px;
    display: block;
  }
}

.root {
  .form {
    border-radius: 5px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 20px 32px;
    max-width: 614px;
    width: 100%;

    @media(max-width: 991px) {
      margin: 0 auto;
      background: #fafafa;
    }

    .formItemWrapper {
      display: flex;

      @media(max-width: 991px) {
        display: block;
      }

      :global {
        .formItem {
          width: 100%;
          padding: 0 8px;
          margin-bottom: 30px;
        }
      }
    }

    button {
      margin-left: auto;
      display: block;
      font-weight: 400;
      width: 100%;
      max-width: 260px;
      margin-right: 8px;

      @media (max-width: 991px) {
        max-width: 100%;
        height: 60px;
        border-radius: 30px;
      }
    }
  }
}
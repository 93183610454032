.root {
  background-color: #fafafa;
  padding-bottom: 90px;

  .wrapper {
    max-width: 930px;
    margin: 0 auto 0;

    @media(max-width: 991px) {
      padding: 0 14px
    }
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    :global {
      label {
        margin-left: 0;
        color: #000000;
        margin-bottom: 20px;

        a {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
}

.submitButton {
  display: block;
  margin-left: auto;
  font-weight: 400;
}

.wrapper {
  :global {

    .ant-steps:not(.ant-steps-navigation) .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title,
    .ant-steps:not(.ant-steps-navigation) .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
    .ant-steps:not(.ant-steps-navigation) .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
      color: inherit;
    }

    .ant-steps-item-container {
      cursor: default !important;
    }

    .ant-steps-item-container:after {
      position: absolute;
      top: 18px;
      left: 94px;
      display: block;
      width: 68px;
      height: 1px;
      background: #e1e1e1;
      content: "";

      @media(max-width: 420px) {
        width: 50%;
        left: 70%;
      }
    }
    .ant-steps-item-container:before {
      position: absolute;
      top: 18px;
      left: -48px;
      display: block;
      width: 80px;
      height: 1px;
      background: #e1e1e1;
      content: "";

      @media(max-width: 420px) {
        width: 50%;
        left: -37%;
      }
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      margin: 0;
      padding-right: 16px;
    }

    .ant-steps {
      padding: 20px 0;
      max-width: 400px;
      margin: 0 auto;

      .ant-steps-item {
        overflow: visible;
        .ant-steps-item-icon {
          display: block;
          margin: 0;
        }

        .ant-steps-item-title {
          display: block;
          padding: 0;
          margin-top: 6px;
          font-size: 12px;
          line-height: 1.5;
          color: #7f7f7f;
        }

        .ant-steps-item-content {
          display: block;
          text-align: center;
        }

        .ant-steps-item-title::after {
          top: -16px;
          display: none;
        }
      }

      .ant-steps-item-process {
        .ant-steps-item-title {
          color: #0e3e92;
        }
      }

      .ant-steps-item-finish {
        .ant-steps-item-title {
          color: #2eb8b1;
        }
      }
    }

    .ant-steps-item {
      &:first-of-type {
        .ant-steps-item-container {
          &:after,
          &:before {
            display: none;
          }
        }
      }

      &:last-of-type {
        .ant-steps-item-container {
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    @media(max-width: 480px) {
      .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
        display: inline-block;
      }
      .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
        float: none;
      }
      .ant-steps-horizontal.ant-steps-label-horizontal {
        display: flex;
      }
      .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
        display: none;
      }
    }
  }
}

.successPayment {
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
  }

  h1 {
    font-size: 18px;
    color: #0e3e92;
    font-weight: 500;
    line-height: 1.53;
    margin-bottom: 4px;
  }

  p {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.53;
    color: #656565;
    margin-bottom: 8px;
  }

  >span {
    font-size: 13px;
    line-height: 1.54;
    color: #000000;
    display: block;
    margin-bottom: 16px;
  }

  button {
    height: 38px;
    margin-bottom: 15px;
  }
}

.termsCondError {
  font-size: 12px;
  color: #e30717;
  margin-bottom: 14px;
  display: block;
  margin-top: -14px;
  margin-left: 30px;
}

.errorBlock {
  padding: 15px 22px;
  border-radius: 4px;
  background: rgba(227, 7, 22, 0.11);
  color: #e30717;
  border: 1px solid;
  margin-bottom: 30px;
}

.checkoutErrorModal {
  img {
    display: block;
    margin: 0 auto;
  }

  h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #0e3e92;
    margin: 10px 0 0;
  }

  p {
    font-size: 12px;
    color: #e30717;
    margin-top: 6px;
    text-align: center;
  }
}
.wrapper {
  border-radius: 5px;
  background-color: #e6e6e6;
  padding: 20px 12px;
  width: 100%;

  @media(max-width: 991px) {
    background-color: #f5f5f5;
  }
}

.field {
  padding: 0 10px;

  :global {
    .DateInput {
      border-radius: 5px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      background-color: #fff !important;
    }

    .DateInput{
      background-repeat: no-repeat !important;
      background-position: 6px center !important;
    }
  }
}

.firstField {
  padding-left: 0;
  width: 100%;
  flex: 1;
}

.searchDropdown {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 20px 0px;
  background-color: rgb(255, 255, 255);

  ul {
    li {
      padding: 10px 12px 10px 40px;
      color: black;

      &:hover {
        background: white !important;
      }
    }
  }
}

.label {
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #000000;
}

.datePicker {
  border: 0;

  :global {
    i {
      display: none;
    }
  }

  input {
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding-left: 40px;
    border: 0;

    &:focus {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    }

    &:hover {
      border-color: white;
    }
  }

  ::placeholder {
    color: #272727;
    opacity: 0.5;
  }
}

.pickerWrapper {
  padding-right: 0;

  :global {
    .DateInput {
      width: 123px;

      .DateInput_input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 1px 1px 41px 8px rgba(0, 0, 0, 0.15);
        background-color: transparent;
      }
    }
  }
}

.selectTimeWrapper {
  padding-left: 0;
}

.selectDateAndTimeWrapper {
  display: flex;
}

.submitButton {
  width: 75px;
  height: 50px;
  border-radius: 5px;
  background-color: #ed4817;
  color: #fff;
  text-transform: uppercase;
  padding: 0;
  margin-top: 30px;
  border: 0;
  margin-left: 10px;

  &:hover {
    opacity: .8;
    background-color: #ed4817;
    color: #fff;
  }
}

.deliverCheckbox {
  margin-top: 20px;
  font-size: 13px;
  color: #000000;
}

.rangePicker {
  :global {
    .DateInput {
      background-image: url('../../../assets/return-date-icon.svg') !important;
    }
  }
}

.datePicker {
  :global {
    .DateInput {
      background-image: url('../../../assets/receiving-date-icon.svg') !important;
    }
  }
}

.fakeInput.place {
  width: 303px;
}

.fakeInput {
  position: relative;

  >button {
    height: 50px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    border: 0;
    outline: none;
    padding: 10px 5px;
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;

    span {
      font-size: 14px;
      line-height: 1.5;
      color: #939393;
    }
  }

  &.active {
    span {
      color: black;
    }
  }
}

.fakeInput.time {
  width: 92px;

  >button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 2px 0px 9px -1px rgba(0, 0, 0, 0.15);
  }
}

.errorField {
  &.fakeInput {
    >button {
      border: 1px solid #e30717;
    }
  }

  :global {
    .DateInput_input {
      border: 1px solid #e30717 !important;
    }
  }

  .error {
    color: #e30717;
  }
}

.root {
  display: flex;
  @media(max-width: 991px) {
    display: block;

    >div {
      padding: 0;
      margin-bottom: 15px;
    }

    .fakeInput.place {
      width: 100%;
    }
    .selectTimeWrapper,
    .pickerWrapper {
      padding: 0;

      :global {
        .DateInput,
        .DateRangePickerInput,
        .DateRangePicker,
        .SingleDatePicker,
        .SingleDatePickerInput {
          width: 100%;
        }
      }
    }
    .pickerWrapper {
      width: 65%;
    }
    .selectTimeWrapper {
      width: 35%;
      .fakeInput.time {
        width: 100%;
      }
    }

    .submitButton {
      height: 50px;
      border-radius: 25px;
      background-color: #ed4817;
      margin: 20px 0 0;
      width: 100%;
    }
  }
}
.root {
  background-color: #fafafa;
}

.wrapper {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 16px 50px;

  >h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #000000;
    margin: 20px 0 16px;
  }

  :global {
    .ant-collapse {
      background-color: transparent;

      .ant-collapse-item {
        background-color: #eaeaea;
        border-radius: 5px;
        margin-bottom: 24px;
        border: 1px solid #fafafa;
        overflow: hidden;

        &:last-of-type {
          margin-bottom: 0;
        }

        .ant-collapse-header {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.5;
          color: #0e3e92;

          i {
            color: #c2c2c2
          }
        }

        p {
          font-size: 13px;
          line-height: 1.54;
          color: #000000;
        }

        .ant-collapse-content-box {
          // padding-top: 0;
        }
      }

      .ant-collapse-item.ant-collapse-item-active {
        border: solid 1px #707070;
      }
    }
  }
}
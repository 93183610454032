.successModal {
  img {
    display: block;
    margin: 0 auto;
  }

  h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #0e3e92;
    margin: 10px 0 0;
  }
}
.modal {
  :global {
    .ant-modal-header {
      background-color: #2959ad;
      padding: 11px 24px;

      .ant-modal-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: #ffffff;
      }
    }

    .ant-modal-body {
      box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.24);
      background-color: #f5f5f5;
      border-radius: 4px;
    }

    .ant-modal-close-x {
      height: 45px;
      font-size: 16px;
      line-height: 45px;
      color: #fff;
    }
  }
}
.root {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 12px;
  position: relative;
}

.top {
  border-bottom: 1px solid #efefef;
  padding: 12px 20px;
  display: flex;
  position: relative;

  @media(max-width: 991px) {
    padding: 12px 8px;
  }
}

.photos {
  position: relative;
  max-width: 180px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;


  @media(max-width: 991px) {
    max-width: 150px;
    margin-bottom: 0;
  }

  :global {
    .ant-carousel {
      .slick-slider {
        min-height: 95px;
      }

      .slick-dots {
        right: 0;
        left: 0;
        bottom: -14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;

        @media(max-width: 991px) {
          bottom: -33px;
          position: initial;
          margin-top: -20px;
        }

        li {
          margin: 0;
          background-color: #bebebe;

          button {
            border-radius: 0;
          }

          &.slick-active {
            button {
              width: inherit;
              background-color: #0e3e92;
            }
          }
        }

        >button {
          font-size: 22px;
          line-height: 1.18;
          color: #0e3e92;
          background: none;
          border: 0;
          font-weight: 500;
          padding: 0;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }

        >span {
          width: 13px;
          height: 19px;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.46;
          letter-spacing: 0.13px;
          text-align: left;
          color: #0e3e92;
          margin: 0 12px
        }
      }
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #000000;
  margin: 0;

  @media(max-width: 991px) {
    padding: 10px 10px 0;
  }

  span {
    font-size: 12px;
    line-height: 1.5;
    color: #707070;
    font-weight: normal;
  }
}

.vehicleDetails {
  margin-left: 32px;
  width: 100%;

  @media(max-width: 991px) {
    margin-left: 20px;
  }

  ul {
    display: flex;
    margin: 32px 0 28px;

    @media(max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin: 12px 0 10px;
    }

    li {
      padding: 0 12px;

      &:last-of-type {
        padding-right: 0;
      }

      &:first-of-type {
        padding-left: 0;
      }

      @media(max-width: 991px) {
        width: auto;
        margin-bottom: 8px;
        padding: 0 7px;

        &:last-of-type {
          padding-right: 7px;
        }

        &:first-of-type {
          padding-left: 7px;
        }
      }

      span {
        font-size: 13px;
        line-height: 1.54;
        color: #000000;
        margin-left: 6px;
        text-transform: capitalize;

        @media(max-width: 991px) {
          font-size: 12px;
          margin-left: 4px;
        }
      }
    }
  }

  .tag {
    border-radius: 5px;
    background-color: #fafafa;
    font-size: 12px;
    line-height: 1.5;
    color: #707070;
    display: inline-block;
    padding: 4px 20px;

    @media(max-width: 991px) {
      display: block;
      width: 100px;
      text-align: center;
      margin-left: auto;
    }
  }

  .price {
    display: inline-block;
    font-size: 13px;
    line-height: 1.5;
    color: #656565;
    margin-left: 12px;

    span {
      font-weight: 600;
      font-size: 15px;
      color: #000;
    }

    @media(max-width: 991px) {
      display: block;
      text-align: right;
      margin-top: 6px;
    }
  }
}

.bottom {
  display: flex;

  @media(max-width: 991px) {
    overflow: auto;
    white-space: nowrap;
    display: block;
  }

  .box {
    width: 33.333%;
    border-right: 1px solid #efefef;
    padding: 12px 26px;

    &:last-of-type {
      border: 0;
    }

    @media(max-width: 991px) {
      width: auto;
      display: inline-block;
      border: 0;
      vertical-align: top;
      padding: 12px 10px;
    }

    h3 {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.54;
      color: #0e3e92;
      margin: 0 0 8px;

      img {
        margin-right: 6px;
      }
    }

    ul {
      li {
        margin: 0;
        padding-bottom: 4px;
        color: #000000;
        >span {
          display: block;
          color: #838383;
          font-size: 11px;
        }

        img {
          margin-right: 2px;
        }
      }
    }
  }

  .reservationBox {
    &:nth-child(1) {
      width: 40%;
    }

    &:nth-child(2) {
      width: 35%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    @media(max-width: 991px) {
      width: auto !important;
    }

    ul {
      li {
        font-size: 12px;

        >.itemInItem {
          display: inline-block;
          width: 165px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }
    }

    .itemInItemWrapper {
      display: flex;
      align-items: center;

      .itemInItem {
        display: flex;
        align-items: center;

        &:first-of-type {
          width: 110px;
        }
      }
    }
  }
}

.listPrice {
  ul {
    li {
      display: flex;

      >span {
        width: 75px;
        font-size: 14px;
        line-height: 2.14;
        text-align: right;
        color: #656565;
        margin-right: 4px;
      }

      strong {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.88;
        text-align: left;
        color: #000000;
        width: 92px;

        span {
          font-weight: 400;
        }
      }
    }
  }
}

.reservation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 180px;
  width: 100%;

  @media(max-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    padding: 0 10px 20px;
  }

  .specialPrice {
    display: none;
  }

  h3 {
    display: none;
  }

  h2 {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.2;
    color: #000000;
    margin: 0;

    span {
      font-size: 20px;
      font-weight: 300;
    }

    strong {
      font-weight: 400;
    }
  }

  button {
    height: 38px !important;
    font-weight: 400 !important;
  }
}

.specialPrice {
  background-color: #0e3e92;
  color: #fff;
  padding: 6px 20px 6px 24px;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 5px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #ffffff;
    border-width: 17px 0 16px 14px;
  }
}

.reservation.discount {
  .specialPrice {
    display: block;
  }

  h3 {
    color: rgba(112, 112, 112, 0.45);
    font-size: 14px;
    position: relative;
    display: inline-block;
    margin-top: -10px;
    text-align: right;
    margin-left: auto;
    display: table;

    @media(max-width: 991px) {
      margin-left: 0;
    }

    span {
      font-size: 20px;
      font-weight: 300;
    }

    strong {
      font-weight: 400;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      top: 4px;
      bottom: 0;
      margin: auto;
      height: 2px;
      background: #6a6a6a;
      transform: rotate(12deg);
    }
  }

  h2 {
    margin-top: 26px;
  }
}

.mobilePrices {
  border-top: 1px solid #efefef;
  padding: 8px 14px 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    li {
      >span {
        font-size: 14px;
        line-height: 2.14;
        text-align: right;
        color: #656565;
      }

      strong {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.88;
        text-align: left;
        color: #000000;
        margin-left: 2px;

        span {
          font-weight: normal;
          margin-left: 4px;
        }
      }
    }
  }

  .total {
    max-width: 132px;
    width: 100%;
    text-align: left;
    >span {
      font-size: 14px;
      line-height: 1.50;
      color: #656565;
      display: block;
    }

    strong {
      font-size: 25px;
      font-weight: bold;
      line-height: 1.2;
      text-align: left;
      color: #000000;
      display: block;
      span {
        font-weight: normal;
        margin-left: 4px;
        &:first-of-type {
          margin-left: 0px;
          font-size: 18px;
        }
      }
    }
  }
}

.boxLi {
  display: flex;
  align-items: center;
}
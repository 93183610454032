.root {
  background-color: #fafafa;
}

.wrapper {
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px 16px;

  @media (max-width: 991px) {
    margin: 0 auto;
    padding: 0;
  }
}

.carousel {
  :global {
    .slick-dots {
      text-align: right;
      right: 12px;
      bottom: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      li {
        margin: 0;

        button {
          border-radius: 0;
        }

        &.slick-active {
          button {
            width: 16px;
          }
        }
      }

      > button {
        font-size: 22px;
        line-height: 1.18;
        color: #c2c2c2;
        background: none;
        border: 0;
        font-weight: 500;
        padding: 0;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      > span {
        width: 13px;
        height: 19px;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.46;
        letter-spacing: 0.13px;
        text-align: left;
        color: #ffffff;
        margin: 0 12px;
      }
    }
  }
}

.homeContent {
  margin-top: 30px;

  @media (max-width: 991px) {
    padding: 0 16px;
  }

  img {
    width: 100%;
  }

  .campaignWrapper {
    display: flex;

    @media (max-width: 768px) {
      display: block;
    }

    .carousel,
    .campaign {
      position: relative;
      // width: 50%;

      h2 {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.53;
        color: #000000;
        margin: 10px 0;
      }

      .campaignContent {
        position: absolute;
        right: 35px;
        top: 55px;
        max-width: 313px;
        text-align: right;

        p {
          font-size: 17px;
          font-weight: 500;
          line-height: 1.76;
          text-align: right;
          color: #ffffff;
        }
      }
    }

    .carousel {
      .campaignContent {
        top: 16px;
      }
    }
  }
}

.downloadArea {
  display: flex;
  height: 200px;
  background-color: #ed4817;
  padding: 16px 44px;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 5px;
  background-image: url("../../assets/download-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  @media (max-width: 768px) {
    display: block;
    padding: 18px 16px;
    background-image: url("../../assets/download-banner-mobile.png");
  }

  h2 {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.5;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .actions {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      display: block;
    }

    a {
      background: none;
      border: 1px solid #fff;
      border-radius: 35px;
      height: 43px;
      padding: 5px 30px;
      max-width: 174px;
      width: 100%;
      display: flex;
      align-items: center;

      &:last-of-type {
        margin-left: 20px;
      }

      @media (max-width: 768px) {
        display: block;

        &:last-of-type {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }
  }
}

.root{
  height: 100vh;
  background-color: #fafafa;
  min-height: 500px;
}
.wrapper {
  max-width: 1128px;
  margin: 0 auto;
  position: relative;
  padding: 0 8px;

  >h1 {
    padding: 20px 0 15px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #000000;
    margin: 0;
  }

  .banner {
    height: 162px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #0e3e92c7;
      border-radius: 4px;
    }
  }

  .bannerInner {
    position: absolute;
    top: 85px;
    left: 48px;

    @media(max-width: 991px) {
      right: 0;
      left: 0;
      margin: 0 auto;
      max-width: 382px;
      padding: 0 16px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      color: #ffffff;

      @media(max-width: 991px) {
        text-align: center;
        margin-top: 6px;
      }
    }

    .form {
      max-width: 360px;
      width: 100%;

      > button {
        height: 60px;
        border-radius: 30px;
        width: 100%;
      }
    }

    .fields {
      border-radius: 5px;
      box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      padding: 12px 23px 23px;
      margin-bottom: 40px;

      h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.56;
        color: #0e3e92;
        margin-bottom: 10px;
      }

      :global {
        .formItem {
          margin-bottom: 24px;
        }

        .ant-checkbox-wrapper {
          font-size: 13px;
          line-height: 1.54;
          color: #404040;

          button {
            color: #404040;
            text-decoration: underline;
            margin: 0 4px;
            border: 0;
            background: none;
          }
        }
      }
    }

    .codeWrapper {
      padding: 32px 48px 24px;

      input {
        text-align: center;
      }
    }
  }
}

.errorText {
  display: none;
}

.error {
  input {
    border: 1px solid #e30717;
  }

  .errorText {
    font-size: 12px;
    color: #e30717;
    margin-top: 6px;
    display: block;
  }
}
.root {
  background-color: #fafafa;
}

.wrapper {
  max-width: 1108px;
  margin: 0 auto;
  padding: 20px 10px;
}

.content {
  display: flex;
  @media(max-width: 991px) {
    display: block;
  }
}

.form {
  width: 60%;
  padding: 30px 20px 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: #e6e6e6;
  @media(max-width: 991px) {
    width: 100%;
  }

  :global {
    .formItem {
      width: 50%;
      padding: 0 8px;
      margin-bottom: 30px;
      @media(max-width: 568px) {
        width: 100%;
      }
    }
  }

  button {
    width: 100%;
  }
}

.errorText {
  display: none;
}

.error {
  textarea,
  input {
    border: 1px solid #e30717;
  }

  .errorText {
    font-size: 12px;
    color: #e30717;
    margin-top: 6px;
    display: block;
  }
}

.banner {
  width: 40%;
  padding: 0 20px;
  @media(max-width: 991px) {
    display: none;
  }
}
.page {
  background-color: #fafafa;
  padding: 20px 0;

  @media(max-width: 991px) {
    padding: 10px 0 32px;
  }
}

.searchForm {
  display: flex;
  margin: 0 auto 12px;
  max-width: 1216px;
  padding: 0 14px;
}

.root {
  display: flex;
  margin: 0 auto;
  max-width: 1216px;

  .content {
    width: 100%;
    padding: 0 14px;

    .field {
      text-align: right;
      padding: 10px 0 15px;
    }
  }
}

.sideBar {
  width: 200px;
  min-width: 200px;

  @media(max-width: 991px) {
    display: none;
    position: fixed;
    z-index: 1;
    background-color: #ffffff;
    width: 100%;
    top: 185px;
    overflow-x: scroll;
    bottom: 0;
  }

  :global {
    .ant-collapse {
      border: 0;
      background: transparent;

      .ant-collapse-header {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.33;
        color: #343434;

        i {
          right: 32px !important;
        }

        svg {
          fill: #c2c2c2;
        }
      }

      .ant-collapse-item {
        border: 0;
      }
    }

    .ant-checkbox-group-item {
      display: block;
      margin-bottom: 10px;
    }
    .ant-checkbox-group {
      display: block;
    }
  }
}

.filters {
  display: flex;
  flex-direction: column;
  padding: 7px 14px;

  .dates {
    display: flex;
    margin: 0 -8px 16px;
    align-items: center;
    justify-content: space-between;

    .refresh {
      padding: 0 8px;
      max-width: 50px;
      width: 100%;

      button {
        border: 0;
        background: none;
        padding: 0;
      }
    }

    .date {
      padding: 0 8px;
      width: 100%;

      div {
        font-size: 14px;
        line-height: 1.5;
        color: #000000;
      }

      span {
        display: block;
        font-size: 14px;
        line-height: 1.5;
        color: #9a9a9a;
      }
    }
  }

  .actions {
    display: flex;
    margin: 0 -8px 16px;

    .field {
      width: 100%;
      padding: 0 8px;

      button {
        height: 28px;
        border-radius: 5px;
        width: 100%;
      }

      select {
        height: 28px;
        border-radius: 5px;
        border: solid 1px #c2c2c2;
        background-color: #fafafa;
        width: 100%;
      }
    }
  }
}

.sideBar.open {
  display: block;
}
.root {
  background-color: #fafafa;
}

.wrapper {
  max-width: 1120px;
  margin: 0 auto;
  padding: 20px 10px 50px;
}

.allCampaings {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.53;
  color: #0e3e92;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  @media(max-width: 991px) {
    justify-content: center;
    margin-top: 32px;
  }
}

.campaigns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  @media(max-width: 768px) {
    display: block;
    margin: 0 0;
  }

  .column {
    width: 50%;
    border-radius: 8px;
    padding: 0 10px;
    margin-bottom: 20px;

    h2 {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.53;
      color: #000000;
      margin: 10px 0;
    }

    @media(max-width: 768px) {
      width: 100%;
      padding: 0 22px;
    }

    .column {
      padding: 0;
      margin-bottom: 0px;
    }
  }

  .homeColumn {
    @media(max-width: 768px) {
      padding: 0 !important;
    }
  }
}

.img,
.campaign, .campaignRed {
  background-image: url('../../assets/blue-white-bg.png');
  min-height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;

  img {
    max-width: 200px;
  }

  >span {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
}

.campaignRed {
  background-image: url('../../assets/red-white-bg.png');
}

.campaignContent, .campaignContentRed {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  padding: 24px 12px 0 0;
  background-repeat: no-repeat;
  background-size: contain;

  p {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.76;
    text-align: right;
    color: #ffffff;
    max-width: 250px;
    text-shadow: 1px 1px 6px #000000;
  }

  button {
    margin-left: auto;
  }
}

.campaignContentRed {
  p {
    font-size: 17px;
    margin-top:30px;
  }  

  button {    
    background-color: #0e3e92 !important;
  }
}

.contentWrapper {
  display: flex;
  margin: 0 -10px;

  @media(max-width: 991px) {
    margin: 0;
    display: block;
  }

  .content,
  .img {
    width: 50%;

    @media(max-width: 991px) {
      width: 100%;
    }
  }

  .content {
    padding: 0 16px;
    @media(max-width: 991px) {
      padding: 0;
    }
    h1 {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      color: #000000;
      margin: 0 0 16px;
    }

    p {
      font-size: 14px;
      line-height: 1.43;
      color: #656565;
    }
    button {
      margin: 16px 0 16px auto;
      display: block;
      @media(max-width: 991px) {
        margin: 16px auto;
        width: 100%;
      }
    }
  }
}
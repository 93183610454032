.root {
  border-radius: 5px;
  background-color: #e6e6e6;
  padding: 10px 30px 20px;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  @media(max-width: 991px) {
    padding: 10px 15px 20px;
  }

  .wrapper {
    display: flex;
    margin: 0 -8px;
    @media(max-width: 991px) {
      display: block;
    }
  }

  h2 {
    margin-bottom: 14px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.53;
    color: #0e3e92;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 80px;
    }
  }

  :global {
    .formItem {
      width: 265px;
      padding: 0 8px;

      &:nth-child(3),
      &:nth-child(4) {
        width: 170px;
      }
      @media(max-width: 991px) {
        width: 100% !important;
        margin-bottom: 30px;
      }
    }
  }
  .errorText {
    display: none;
  }
}

.modalWrapper {
  .errorText {
    display: none;
  }
  .formItemWrapper {
    display: flex;
    @media(max-width: 991px) {
      display: block;
    }
    :global {
      .formItem {
        width: 100%;
        padding: 0 8px;
        margin-bottom: 20px;
      }
    }
  }
  button {
    margin-left: auto;
    display: block;
    font-weight: 400;
  }
}

.paymentCheckbox {
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @media(max-width: 991px) {
    background-color: #e6e6e6;
  }
  h2 {
    font-size: 13px;
    font-weight: 500;
    color: #0e3e92;
  }
}

.error {
  textarea,
  input {
    border: 1px solid #e30717;
  }
  .errorText {
    font-size: 12px;
    color: #e30717;
    margin-top: 6px;
    display: block;
  }
}

.openModalButton {
  border: 0;
  background: none;
  font-size: 13px;
  color: #0e3e92;
  margin-top: 14px;
  text-decoration: underline;
}
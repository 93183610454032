.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .2s, opacity .2s linear;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);

  .field {
    padding: 20px 20px 10px;
    position: relative;

    img {
      position: absolute;
      top: 8px;
      left: 16px;
      bottom: 0;
      margin: auto;
    }
  }

  input {
    border: 0;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    height: 30px;
    font-size: 14px;
    color: #000000;
    padding: 0 0 0 30px;

    &:focus {
      outline: none;
      box-shadow: none;
      border-bottom-color: #ed4817;
    }
  }

  .list {
    max-height: 205px;
    overflow-y: auto;
    padding: 2px 0 12px;

    ul {
      li {
        button {
          padding: 12px 20px;
          display: flex;
          align-items: center;
          width: 100%;
          height: 38px;
          background: none;

          span {
            font-size: 13px;
            color: #000000;
            line-height: 1px;
          }

          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.root.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s linear;
}

.noData {
  text-align: center;
}
.root {
  :global {
    .react-phone-number-input__row {
      position: relative;
    }
    .react-phone-number-input__country {
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
    }

    .react-phone-number-input__icon {
      img {
        display: block;
      }
    }
    input {
      padding-left: 56px;
    }
  }
}
.root{
  background-color: #292929;
  padding: 48px 22px;
}

.wrapper  {
  max-width: 1220px;
  margin: 0 auto;
  color: #bababa;
  position: relative;

  :global {
    .ant-back-top {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.about {
  * {
    display: block;
    color: #fff;
  }
  img {
    margin-bottom: 25px;
  }
  a {
    color: #bababa;
    &:hover {
      color: #ed4817;
    }
  }
  p {
    margin: 0;
  }
}
.list {
  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.79;
    color: #bababa;
    margin-bottom: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 8px;
      a {
        color: #bababa;
        font-size: 12px;
        line-height: 2.08;
        &:hover {
          color: #ed4817;
        }
      }
    }
  }
}
.social {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media(max-width: 768px) {
    align-items: flex-start;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.79;
    color: #bababa;
    margin-bottom: 10px;
  }
  ul {
    margin: 0 0 30px;
    padding: 0;
    list-style: none;
    display: flex;
    li {
      padding: 0 15px;
      &:last-of-type{
        padding-right: 0;
      }
      &:first-of-type{
        padding-left: 0;
      }
    }
  } 
  p {
    font-size: 9px;
    line-height: 1.44;
    color: #a3a3a3;
  }
  .downloadArea {
    margin-bottom: 30px;
    .actions {
      display: flex;
      align-items: center;
  
      a, button {
        background: none;
        border: 1px solid #fff;
        border-radius: 35px;
        height: 31px;
        padding: 4px 0;
        width: 118px;
        cursor: pointer;
        text-align: center;
  
        &:last-of-type {
          margin-left: 20px;
        }
        img {
          width: 75px;
        }
      }
    }
  }
}
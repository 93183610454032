.root {
  background-color: #fafafa;
  padding: 20px 0;

  .wrapper {
    max-width: 1070px;
    margin: 0 auto;

    >h2 {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      color: #000000;

      @media(max-width: 991px) {
        padding: 0 14px;
      }
    }

    >ul {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;

      li {
        padding: 0 25px;

        @media(max-width: 991px) {
          padding: 0 10px;
        }

        a {
          font-size: 17px;
          line-height: 1.29;
          color: #a7a7a7;

          @media(max-width: 991px) {
            font-size: 14px;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        .active {
          color: #ed4817;

          &:after {
            content: '';
            width: 44px;
            height: 5px;
            border-radius: 7px;
            background-color: #ed4817;
            display: block;
            margin: 5px auto 0;
          }
        }
      }
    }
  }
}

.logout,
.kvkLink {
  font-size: 13px;
  line-height: 1.54;
  color: #404040;
  margin-top: 10px;
  display: block;
  background: none;
  border: 0;

  @media(max-width: 991px) {
    padding: 0 14px;
    margin: 35px auto 5px;
    text-align: center;
  }

}

.logout {
  color: #e30717;
  margin: 16px auto;
}

.details {
  display: flex;
  margin: 0 -10px;

  @media(max-width: 991px) {
    display: block;
    margin: 0 auto;
    max-width: 600px;
    padding: 0 4px;
  }

  .left {
    padding: 0 10px;
  }

  .right {
    padding: 0 10px;
    width: 100%;
    align-items: center;
    display: flex;

    button {
      width: 100%;
      height: 50px;
      font-weight: 400;

      @media(max-width: 991px) {
        margin-top: 20px;
      }
    }
  }
}

.detailsWrapper {
  max-width: 980px;
  margin: 0 auto;

  @media(max-width: 991px) {
    padding: 0 5px;
  }
}

.rent {
  border-radius: 5px;
  background-color: #e6e6e6;
  padding: 20px 16px;
  margin-bottom: 16px;

  @media(max-width: 991px) {
    padding: 14px 8px;
  }
}

.rentHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;

  @media(max-width: 991px) {
    display: block;
    text-align: center;
  }

  h3 {
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    margin: 0;

    @media(max-width: 991px) {
      margin-bottom: 16px;
    }
  }

  div {
    display: flex;
    align-items: center;

    @media(max-width: 991px) {
      justify-content: center;
    }

    span {
      font-size: 13px;
      line-height: 1.54;
      color: #000000;

      &:last-of-type {
        color: #fff;
        border-radius: 18px;
        padding: 4px 16px;
        margin-left: 8px;
      }
    }

    .approved {
      background-color: #2eb8b1;
    }

    .canceled {
      background-color: #9b2121;
    }

    .waiting {
      background-color: #e38330;
    }
  }
}
.openModalButton {
  border: 0;
  background: none;
  font-size: 13px;
  color: #0e3e92;
  margin-top: 14px;
  text-decoration: underline;
}
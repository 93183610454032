.button {}

.button.primary {
  border: 0;
  background-color: #ed4817;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #ffffff;
  height: 36px;
  padding: 0 36px
}

.button.round {
  border-radius: 23px;
}
.wrapper {
  height: 90px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);
  background-color: #0e3e92;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 991px) {
    height: 64px;
  }
}

.header {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;

  @media (max-width: 991px) {
    justify-content: center;
  }

  nav {
    @media (max-width: 991px) {
      position: fixed;
      background: #0e3e92;
      top: 63px;
      bottom: 0;
      left: 0;
      right: 100%;
      z-index: 2;
      transition: all .2s ease-in-out;
      overflow-x: hidden;
      width: 0%;
      opacity: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        display: block;
        padding: 0 32px;
        max-width: 480px;
        margin: 0 auto;
      }

      @media (max-width: 340px) {
        padding: 0 16px;
      }

      li {
        a {
          display: block;
          color: #d5e3fc;
          margin: 0 30px;
          text-decoration: none;
          font-size: 16px;
          position: relative;

          @media (max-width: 991px) {
            margin: 0 0 50px;
            font-size: 22px;
            color: #ffffff;
          }
        }

        .active {
          color: #ed4817;

          &:after {
            content: '';
            height: 5px;
            border-radius: 7px;
            background-color: #ed4817;
            position: absolute;
            top: 25px;
            left: 4px;
            right: 4px;
          }
        }
      }

      .loginButton {
        a {
          width: 170px;
          height: 45px;
          border-radius: 23px;
          border: solid 1px #5f8bd6;
          background-color: #0e3e92;
          text-align: center;
          line-height: 45px;
          margin-left: 0;
          margin-right: 0;

          @media (max-width: 991px) {
            width: 100%;
            margin-top: 20px;
          }
        }
      }

      .me {
        a {
          border: solid 1px #ed4817;
        }
      }
    }
  }

  nav.menuOpened {
    right: 0%;
    overflow-x: auto;
    width: 100%;
    opacity: 1;
  }
}

.logo {
  a {
    display: block;
    max-width: 170px;

    @media (max-width: 991px) {
      max-width: 164px;
    }
  }
}

.langChanger.mobile {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.langChanger.desktop {
  display: none;

  @media (min-width: 991px) {
    display: block;
  }
}

.langChanger {
  cursor: pointer;
}

.langChangerDropdown {
  width: 172px;
  height: 45px;
  border-radius: 23px;
  line-height: 45px;
  text-align: center;
  border: solid 1px #5f8bd6;
  background-color: #0e3e92;
  display: flex;
  margin-left: 18px;

  @media (max-width: 991px) {
    width: 100%;
    margin: 24px 0 60px;
  }

  span {
    display: block;
    width: 48px;
    line-height: 41px;
    border-right: 1px solid #5f8bd6;
  }

  strong {
    display: block;
    color: #d5e3fc;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;

    @media (max-width: 991px) {
      font-size: 18px;
      color: #fff;
    }

    i {
      margin-left: 14px;
      font-size: 10px;
    }
  }
}

.langMenu {
  width: 170px;

  li {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;

    &:last-of-type {
      border: 0;
    }

    img {
      margin-right: 4px;
    }
  }
}

.menuOpenButton {
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  display: none;
  background: none;
  border: 0;

  @media (max-width: 991px) {
    display: block;
  }
}